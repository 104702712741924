@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
/* Style sheet for index html file */

/* Some basic settings for the whole body */
html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* background: url("./images/sabri-tuzcu-317828.jpg") no-repeat center center fixed; */
  background: url(/static/media/sabri-tuzcu-317828.4e0cf73b.jpg) no-repeat center
    center fixed;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

button:hover {
  color: black !important;
}

.jumbotron {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100vh;
  /* background-image: url('./images/hannah-morgan-64028.jpg'); */
  background-image: url(/static/media/hannah-morgan-64028.3b886ec0.jpg);
  background-size: cover;
}

.minty-text {
  font-family: 'Lobster', sans-serif;
  color: white;
  text-shadow: 3px 3px teal;
}

.minty-button {
  color: white;
  background-color: teal;
}

.minty-button:hover {
  color: teal;
  background-color: white;
}

.minty-dropdown-button {
  font-family: 'Lobster', sans-serif;
}

/* This class will vertically center a div */
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

/**********************************************

# Main Page

**********************************************/

#title-div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

#navbar-container {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

/**********************************************

# Login Page

**********************************************/

#login-container {
  width: 40%;
}

#login-header {
  font-size: 6em;
  padding-bottom: 5%;
}

#login-submit-button {
  color: white;
  background-color: teal;
}

#login-submit-button:hover {
  color: teal;
  background-color: white;
}

#login-error {
  color: #ffffff;
  text-shadow: 2px 2px #ff647d;
}

.loginText {
  font-size: 2em;
}

/**********************************************

# Dashboard Page
# NOTE ALL PAGES WILL SHARE THESE CLASSES TO MAINTAIN CONSISTENCY

**********************************************/

#dashboard-homepage {
  margin: 0 auto;
  padding-top: 5%;
  width: 100%;
  height: 100vh;
}

#dashboard-home-container {
  width: 22em;
  height: 22em;
  border-radius: 50%;
  border: 3px solid white;
  background-color: black;
  text-align: center;
}

#dashboard-welcome {
  padding-top: 27%;
  font-size: 4em;
}

#dashboard-header {
  text-align: center;
  font-size: 6em;
}

#dashboard-header h1 {
  font-size: 1em;
}

.delete-row {
  /* height: 8%; */
  background-color: #ff647d;
  color: white;
}

.delete-row:hover {
  background-color: white;
}

#deleteComponent {
  background-color: #ff647d;
}

#deleteComponent:hover {
  background-color: white;
}

.icetrack-table {
  color: white;
  background-color: black;
}

.icetrack-form-div form {
  padding: 15px;
  background-color: black;
  border-radius: 20px;
  color: white;
}

.slide-enter,
.slide-exit {
  transition: all 1000ms ease-out;
}

.slide-enter {
  opacity: 0;
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
  opacity: 1;
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
  opacity: 1;
}

