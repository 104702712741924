.slide-enter,
.slide-exit {
  transition: all 1000ms ease-out;
}

.slide-enter {
  opacity: 0;
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
  opacity: 1;
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
  opacity: 1;
}
